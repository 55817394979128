import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css"; // Import styles for Toastify
import { serverUrl } from "../../App";
import { ClipLoader } from "react-spinners";

export const Create = () => {
  const navigate = useNavigate();
  const [userMasterObject, setUserMasterObject] = useState(null);
  const [selectedDropdownOuId, setSelectedDropdownOuId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ouOptions, setOuOptions] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [show, setShow] = useState(false);
  const [csvTotalBuCount, setCsvTotalBuCount] = useState(null);
  const [newlyAddedCount, setnewlyAddedCount] = useState(null);
  const [existingBuCodesCount, setexistingBuCodesCount] = useState(null);
  const [existingBuCodes, setexistingBuCodes] = useState([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [formData, setFormData] = useState({
    serviceStationName: "",
    serviceStationRegion: "",
    serviceStationCode: "",
    serviceStationChannel: "",
    serviceStationLocation: "",
    deviceCount: "",
  });
  const [errors, setErrors] = useState({
    serviceStationName: "",
    serviceStationRegion: "",
    serviceStationCode: "",
    serviceStationChannel: "",
    serviceStationLocation: "",
    deviceCount: "",
  });

  const toBulkUploadServiceStation = () => {
    navigate("/bulkUploadServiceStation");
  };

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");
        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (user?.entity?.roleOuObjs?.length > 0) {
      const uniqueOUs = user.entity.roleOuObjs.map((role) => ({
        id: role.ouMasterObj.id,
        ouName: role.ouMasterObj.ouName,
      }));
      const uniqueOUsFiltered = uniqueOUs.filter(
        (ou, index, self) =>
          index ===
          self.findIndex((t) => t.id === ou.id && t.ouName === ou.ouName)
      );
      setOuOptions(uniqueOUsFiltered);
    } else {
      navigate("/");
    }
  }, [navigate, user]);

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  const sendDataCreateServceStation = async () => {
    // debugger;

    setIsLoading(true); // Set loading to true when sending data

    try {
      const currentDateTime = new Date(); // current date time stand
      const formObject = {
        buRegion: formData.serviceStationRegion,
        buCode: formData.serviceStationCode,
        buName: formData.serviceStationName,
        buLocation: formData.serviceStationLocation,
        buChannel: formData.serviceStationChannel,
        deviceCount: formData.deviceCount,
      };

      const buList = [formObject];

      // console.log("Before Api call:", currentDateTime.toLocaleString());
      const response = await fetch(serverUrl + "bu/create", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId, // Use selectedDropdownOuId here
          buList: buList,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          setnewlyAddedCount(data.entity.newlyAddedCount);

          setexistingBuCodesCount(data.entity.existingBuCodesCount);

          setexistingBuCodes(data.entity.existingBuCodes);

          handleSuccess();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "organizationName") {
      setSelectedDropdownOuId(value); // Update selectedDropdownOuId with the selected value
      // console.log("Selected Dropdown ou id", value);
    }
  };

  const handleFormData = (e) => {
    const { name, value } = e.target;

    if (name === "serviceStationName") {
      const isNameValid =
        value === "" || /^[A-Za-z][A-Za-z0-9.\s]{0,99}$/.test(value); // Allow empty or valid name
      if (isNameValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters, numbers, spaces, and dots are allowed. Should start with a letter and be less than 100 characters.",
        }));
      }
    } else if (name === "serviceStationRegion") {
      const isRegionValid = /^[A-Za-z0-9]{0,3}$/.test(value); // No changes needed here
      if (isRegionValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Alphanumeric characters only, max 3 characters.",
        }));
      }
    } else if (name === "serviceStationCode") {
      const isCodeValid = value === "" || /^[A-Za-z0-9-]{0,20}$/.test(value); // Allow empty or valid code
      if (isCodeValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only alphanumeric characters and dashes (-) are allowed, max 20 characters.",
        }));
      }
    } else if (name === "serviceStationChannel") {
      const isChannelValid = /^[A-Za-z\s]{0,30}$/.test(value); // No changes needed here
      if (isChannelValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters and spaces are allowed, max 30 characters.",
        }));
      }
    } else if (name === "serviceStationLocation") {
      const isLocationValid = /^[A-Za-z\s]{0,50}$/.test(value); // No changes needed here
      if (isLocationValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters and spaces are allowed, max 50 characters.",
        }));
      }
    } else if (name === "deviceCount") {
      const count = Number(value);
      // Allow empty input to clear the error when backspace is pressed
      if (value.trim() === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value, // Allow empty input
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if input is empty
        }));
      } else if (Number.isInteger(count) && count > 0 && count <= 30) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid input. Must be a positive number between 1 and 30.",
        }));
      }
    }
  };

  const handleCancelDilog = () => {
    setShowConfirmDialog(false);
  };

  const handleAddClick = (e) => {
    // debugger;
    e.preventDefault();
    setShowConfirmDialog(true);
  };

  const handleConfirm = () => {
    // debugger;
    setShowConfirmDialog(false);
    sendDataCreateServceStation();
  };

  const handleCancelSuccessModal = () => {
    setShow(false);
  };
  // Check if all required fields are filled
  const isFormValid = () => {
    return (
      formData.serviceStationName &&
      formData.serviceStationRegion &&
      formData.serviceStationCode &&
      formData.serviceStationChannel &&
      formData.serviceStationLocation &&
      formData.deviceCount &&
      selectedDropdownOuId
    );
  };

  const handleShowSuccessModal = () => {
    const message = `Successfully added ${newlyAddedCount} service station.`;
    setSuccessMessage(message);
    setShowSuccessModal(true);
  };
  const handleSuccess = () => {
    setSuccessMessage({ newlyAddedCount, existingBuCodesCount });

    handleShowSuccessModal();

    setShow(true);
  };

  // Define handleCancelClick function
  const isAnyFieldFilled = () => {
    return (
      (formData.serviceStationName || "").trim() !== "" ||
      (formData.organizationName || "").trim() !== "" ||
      (formData.serviceStationRegion || "").trim() !== "" ||
      (formData.serviceStationCode || "").trim() !== "" ||
      (formData.serviceStationChannel || "").trim() !== "" ||
      (formData.serviceStationLocation || "").trim() !== "" ||
      (formData.deviceCount || "").trim() !== ""
    );
  };

  // Updated handleCancelClick function
  const handleCancelClick = () => {
    if (isAnyFieldFilled()) {
      setShowCancelDialog(true);
    }
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };

  const handleCancelConfirm = () => {
    navigate("/superAdmin"); // Navigate to the home page
  };

  return (
    <>
      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid mx-0 px-0">
            <ToastContainer />
            <div className="col-md-12 px-0">
              <div className="row px-0 mx-0">
                <div className="col-md-12 px-0">
                  <Header />
                </div>
              </div>

              {/* Create Service Station Form Start */}
              <div className="row px-1 mx-0" style={{ marginTop: "20px" }}>
                <div style={{ filter: isLoading ? "blur(5px)" : "none" }}>
                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="text-center" style={{ flex: 2 }}>
                              Create Service Station
                            </h5>
                            <Button
                              variant="primary"
                              onClick={toBulkUploadServiceStation}
                            >
                              Bulk Create
                            </Button>
                          </div>
                          <Form>
                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "25px",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationName"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationName"
                                  id="serviceStationName"
                                  placeholder="Enter Service Station Name"
                                  value={formData.serviceStationName} // Bind value to formData
                                  onChange={handleFormData}
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                              </div>

                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="organizationName"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Select Organization Unit
                                </label>
                                <select
                                  id="organizationName"
                                  name="organizationName"
                                  className="form-control"
                                  onChange={handleInputChange}
                                  required
                                  style={{
                                    appearance: "auto",
                                  }}
                                >
                                  <option value="">Select Organization</option>
                                  {ouOptions.map((ou, index) => (
                                    <option key={index} value={ou.id}>
                                      {ou.ouName}
                                    </option>
                                  ))}
                                </select>
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                              </div>
                            </div>

                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "25px",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationRegion"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Region
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationRegion"
                                  id="serviceStationRegion"
                                  value={formData.serviceStationRegion}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Region"
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                              </div>
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationCode"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationCode"
                                  id="serviceStationCode"
                                  value={formData.serviceStationCode}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Code"
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                              </div>
                            </div>

                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="serviceStationLocation"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Channel
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationChannel"
                                  id="serviceStationChannel"
                                  value={formData.serviceStationChannel}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Channel"
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                              </div>
                              <div
                                className="form-group"
                                style={{
                                  flex: "0 0 48%",
                                  marginBottom: "25px",
                                }}
                              >
                                <label
                                  htmlFor="serviceStationLocation"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Service Station Location
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serviceStationLocation"
                                  id="serviceStationLocation"
                                  value={formData.serviceStationLocation}
                                  onChange={handleFormData}
                                  placeholder="Enter Service Station Location"
                                  required
                                />
                                <div className="invalid-feedback">
                                  This field is required.
                                </div>
                              </div>
                            </div>

                            <div
                              className="form-row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ flex: "0 0 48%" }}
                              >
                                <label
                                  htmlFor="deviceCount"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Device Count
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="deviceCount"
                                  name="deviceCount"
                                  value={formData.deviceCount}
                                  onChange={handleFormData}
                                  placeholder="Enter Device Count"
                                  required
                                  min="1"
                                  onInput={(e) => {
                                    if (e.target.value <= 0)
                                      e.target.value = "";
                                  }}
                                />
                                <div className="invalid-feedback">
                                  Device count must be a positive number.
                                </div>
                              </div>
                            </div>

                            <div className="text-center mt-4">
                              <Button
                                type="submit"
                                className="mx-2 btn btn-primary"
                                onClick={handleAddClick}
                                disabled={!isFormValid()}
                              >
                                Create
                              </Button>

                              <Button
                                className="btn btn-secondary"
                                type="button"
                                onClick={handleCancelClick}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Create Service Station Form End */}
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirmDialog} onHide={handleCancelDilog}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add this service station?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDilog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* success Dialog */}
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            {newlyAddedCount > 0 ? <b>Success</b> : <b>Alert</b>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Newly Added Count: {newlyAddedCount}</p>
          <p>Existing BuCodes Count: {existingBuCodesCount}</p>
          <div>
            {newlyAddedCount === 0 ? (
              <>
                <h4>Existing BU Codes</h4>
                <div
                  style={{
                    maxHeight: "150px",
                    overflow: "auto",
                  }}
                >
                  {/* Adjust maxHeight as needed */}
                  <ol>
                    {Object.entries(existingBuCodes).map(([key, value]) => (
                      <li key={key}>{value}</li>
                    ))}
                  </ol>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div style={{ color: "green" }}>
            {newlyAddedCount > 0 ? (
              <div>
                {newlyAddedCount == 1 ? (
                  <b>
                    {newlyAddedCount} Service station added successfully...!
                  </b>
                ) : (
                  <b>
                    {newlyAddedCount} Service stations added successfully...!
                  </b>
                )}
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Loader spinner */}
      {isLoading && (
        <div
          className="loading-spinner"
          style={{ textAlign: "center", paddingTop: "20px" }}
        >
          <ClipLoader color="#007bff" loading={isLoading} size={50} />
          <p>Please Wait..</p>
        </div>
      )}
      {/* Cancel Confirmation Modal */}
      <Modal show={showCancelDialog} onHide={handleCancelDialogClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCancelConfirm}
            style={{ backgroundColor: "red", borderColor: "red" }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCancelDialogClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Create;
