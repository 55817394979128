import { Button } from "react-bootstrap";

function ErrorFallBack({ error, resetErrorBoundary }) {

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <Button variant="primary" onClick={resetErrorBoundary}>Try Again</Button>
    </div>
  );
}
export default ErrorFallBack;
