import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";

const GetHelpVideos = () => {
  return (
    <>
      <div className="body-pd" style={{ height: "70vh" }}>
        <div className="secondary-color">
          <Header />
          <div className="container-fluid mx-0 p-4">
            <h3 className="text-center mb-3">Videos</h3>
            <div
              className="d-flex flex-column align-items-center justify-content-center p-5"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              <div>
                <iframe
                  width="800"
                  height="400"
                  src="https://www.youtube.com/embed/9yAceTlnfU8?si=pfYhMgFshUArL0ti"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetHelpVideos;
