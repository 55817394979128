import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import VehiclesTestedList from "./Components/Pages/VehiclesTestedList";
import DevicesList from "./Components/Pages/DevicesList";
import ServiceStaionsList from "./Components/Pages/ServiceStaionsList";
import ActiveVehiclesList from "./Components/Pages/ActiveVehiclesList";
import MenuBar from "./Components/Pages/Menubar";
import GetHelpVideos from "./Components/Pages/GetHelpVideos";
import ErrorFallBack from "./ErrorPage";
import AppDownloadPage from "./Components/Pages/AppDownloadPage";
import AllVehiclesTestedList from "./Components/Pages/AllVehiclesTestedList";
import { ErrorBoundary } from "react-error-boundary";
import Home from "./Components/Pages/Home";
import Create from "./Components/Pages/Create";
import BulkUploadServiceStation from "./Components/Pages/BulkUploadServiceStation";

const UnusedDevicesList = React.lazy(() =>
  import("./Components/Pages/UnusedDevicesList")
);
const WheelsAnalysisPreview = React.lazy(() =>
  import("./Components/Pages/WheelsAnalysisPreview")
);
const Dashboard = React.lazy(() => import("./Components/Pages/Dashboard"));
const SignIn = React.lazy(() => import("./Components/Pages/SignIn"));
const WheelsAnalysisReport = React.lazy(() =>
  import("./Components/Pages/WheelsAnalysisReport")
);
export const // serverUrl = "http://192.46.214.143:8080/suspensiontesterserver/rest/";
  // serverUrl = "http://localhost:8080/suspensiontesterserver/rest/";
serverUrl = "https://suspension.roadbounce.com/suspensiontesterserver/rest/";
export const assetPath = "https://suspension.roadbounce.com/RBEAssets/";

function App() {
  return (
    <div className="p-0 m-0">
      <BrowserRouter>
        <div>
          {/* <MenuBar /> */}
          <Routes>
            <Route
              path="/"
              element={
                <ErrorBoundary fallback={ErrorFallBack} onReset={() => {}}>
                  <Suspense fallback="Loading">
                    <SignIn />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ErrorBoundary fallback={ErrorFallBack} onReset={() => {}}>
                  <Suspense fallback="Loading">
                    <Dashboard />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/:pageType"
              element={
                <ErrorBoundary fallback={ErrorFallBack} onReset={() => {}}>
                  <Suspense fallback="Loading">
                    <DevicesList />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/serviceStations"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <ServiceStaionsList />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/activeVehicles"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <ActiveVehiclesList />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/vehiclesTested/:id/:idType"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <VehiclesTestedList />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/:id/:idType/wheelsAnalysisReport/:vehicleTestId/:ouMasterId"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <WheelsAnalysisReport />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/appDownloadPage"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <AppDownloadPage />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/unusedDevices"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <UnusedDevicesList />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/userGuide"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <GetHelpVideos />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/WheelsAnalysisPreview/:vehicleTestId/:ouMasterId"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <WheelsAnalysisPreview />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/AllvehiclesTestedList"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <AllVehiclesTestedList />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/wheelsAnalysisReport/:vehicleTestId/:ouMasterId"
              element={
                <ErrorBoundary fallback={ErrorFallBack}>
                  <Suspense fallback="Loading">
                    <WheelsAnalysisReport />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/home"
              element={
                <ErrorBoundary fallback={ErrorFallBack} onReset={() => {}}>
                  <Suspense fallback="Loading">
                    <Home />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/create"
              element={
                <ErrorBoundary fallback={ErrorFallBack} onReset={() => {}}>
                  <Suspense fallback="Loading">
                    <Create />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/bulkUploadServiceStation"
              element={
                <ErrorBoundary fallback={ErrorFallBack} onReset={() => {}}>
                  <Suspense fallback="Loading">
                    <BulkUploadServiceStation />
                    <MenuBar />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            {/* <Route path="*" element={<ErrorPage />} /> */}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
